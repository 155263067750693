.height-224px {
  max-height: 224px;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
