.height-width-32px {
  height: 32px;
  width: 32px;
}

.height-and-width-44px {
  height: 44px;
  width: 44px;
}

.height-width-16px {
  height: 16px;
  width: 16px;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.flex-grow {
  flex-grow: 1;
}

.z-index-101 {
  z-index: 101;
}

.border-radius-top-default {
  border-radius: 8px 8px 0 0;
}

.container-height {
  height: calc(100% - 105px);
}

.max-height-44px {
  max-height: 44px;
}

/* --- media queries --- */
@media screen and (min-width: 850px) {
  body {
    background-color: #f5f5f5;
  }

  .large-screen-bg-color {
    background-color: white;
  }

  .large-screen-padding {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .large-screen-width {
    width: 850px;
  }

  .large-screen-height {
    height: 80%;
  }

  .large-screen-border {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
  }
}

.height-16px {
  height: 16px;
}

.height-25px {
  height: 25px;
}

.height-40px {
  height: 40px;
}

.height-80px {
  height: 80px;
}

.height-120px {
  height: 120px;
}

.height-180px {
  height: 180px;
}

.height-200px {
  height: 200px;
}

.height-350px {
  height: 350px;
}

.min-height-70px {
  min-height: 70px;
}

.width-1px {
  width: 1px;
}

.width-2px {
  width: 2px;
}

.width-16px {
  width: 16px;
}

.width-30px {
  width: 30px;
}

.width-100px {
  width: 100px;
}
.width-150px {
  width: 150px;
}

.width-250px {
  width: 250px;
}
